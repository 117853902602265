// Helper utilities for the columns
export const Columns = {
  /**
   * A helper function that allows for columns to be formatted based on afinity
   * @param columns - The table columns that need to be formatted
   * @param data - The data that is going into the table
   */
  formatColumns: (columns: any, data: any) => {
    const newColumns = [];
    if (Object.keys(data).length) {
      const tableData = data[Object.keys(data)[0]];
      if (tableData.length) {
        const includeColumns = Object.keys(tableData[0]);
        // Loop through the list of columns we already have set up
        for (const column of columns) {
          // Loop through the names of the fields the API returned
          for (const columnName of includeColumns) {
            // If we have data matching that column, return the column
            if (columnName === column.name) {
              newColumns.push(column);
            }
          }
        }
      }
    }
    return newColumns;
  },
};
