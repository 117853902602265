export const Numbers = {
  /**
   * A function used to format the numbers in the table to the proper type
   * @param columnName - The name of the column that needs formatting
   * @param value - The value of the number being passed into the
   */
  prettifyNumber: (value: string, type: 'percent' | 'dollar' | 'none') => {
    switch (type) {
      case 'dollar':
        return (
          '$' +
          parseFloat(value)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
        );
      case 'percent':
        return (
          parseFloat(value)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,') + '%'
        );
      default:
        return value;
    }
  }
};