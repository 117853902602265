import React from 'react';
import { KeywordsTable } from '../../subpages';

/**
 * The keywords page
 */
export const Keywords = () => {
  return (
    <div className='full-container'>
      <KeywordsTable />
    </div>
  );
};
