import { useState, useEffect } from 'react';

/**
 * Tracks any date filters applied in the URLs and returns a string that can be used to
 * relative links that persist the date filter
 */
export const useDateDrillDown = () => {
  // If there are filters in the url, retrieve the filters
  const urlFilter = window.location.hash ? JSON.parse(decodeURI(window.location.hash).replace('#', '')) : {};
  // Create drill down link that keeps date filter
  const [dateDrillDown, setDateDrillDown] = useState(urlFilter.date ? `#${JSON.stringify({ date: urlFilter.date })}` : '');

  // Logic for adding in the JSON parse to the url when filters, startDate, endDate or sort are changed
  useEffect(() => {
    // If the filter date is no longer default, add it to the filter URL
    if (urlFilter.date) {
      setDateDrillDown(`#${JSON.stringify({ date: urlFilter.date })}`);
    }
    // Clear date drill down if there is no longer a special date range
    else {
      setDateDrillDown('');
    }
  }, [urlFilter]);

  return {
    dateDrillDown,
  };
};
