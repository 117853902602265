import React, { useEffect } from 'react';
import { DeprecatedDataTable, CustomHeadRender, DatePicker, CustomFilterList, CustomFooter } from 'lt-components';
import { Link } from 'react-router-dom';
import { useFilters, useFormatData } from '../../hooks';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo-hooks';
import { Filter, Columns, Numbers } from '../../utilities';
import { NotificationStore, UserStore } from '../../stores';
import './CampaignsTable.scss';

/**
 * Get campaigns graphql query
 */
const GET_CAMPAIGNS = gql`
  query getCampaigns {
    campaigns(filters: $filters) @rest(type: "Campaign", path: "/get-campaigns/?{args}") {
      account
      accountId
      campaign
      campaignId
      platform
      status
      impressions
      clicks
      cost
      ctr
      mds
      cpmd
      leads
      cpl
      mesoFormLeads
      cpmfl
      accounts
      cpa
      mesoAccounts
      cpma
      qualifiedLeads
      cpql
      viables
      cpv
      accountSendovers
      cpas
      accountMeetings
      cpam
      customers
      cpcust
    }
  }
`;

/**
 * Get Campaigns graphql query for only people with agency permissions
 */
const GET_CAMPAIGNS_AGENCY_ONLY = gql`
  query getCampaigns {
    campaigns(filters: $filters) @rest(type: "Campaign", path: "/get-campaigns/?{args}") {
      account
      accountId
      campaign
      campaignId
      platform
      status
      impressions
      clicks
      cost
      ctr
      cpc
      maxCpc
      mds
      cpmd
      leads
      cpl
      qualifiedLeads
      cpql
      customers
      cpcust
    }
  }
`;

/**
 * Defines expected props for this component
 */
interface IProps {
  /**
   * the initial filter options determined by the parent drill-down
   */
  filters?: {
    /**
     * the id of the account that was drilled-down
     */
    accountId?: number;
  };
}

/**
 * The campaign table component (handles it's own state / filters)
 */
export const CampaignsTable = (props: IProps) => {
  // Manages the state of the filters and the table
  const {
    filters,
    sort,
    filterStartDate,
    filterEndDate,
    appliedStartDate,
    appliedEndDate,
    columnDisplay,
    setColumnDisplay,
    setFilterDate,
    setAppliedDate,
    setSort,
    setFilters,
    dateDrillDown,
  } = useFilters({ column: 'campaign', direction: 'asc' });

  // Query database with graphql (applying filters)
  const { data, error, loading } = useQuery(UserStore.user && UserStore.user.mesoPermissions ? GET_CAMPAIGNS : GET_CAMPAIGNS_AGENCY_ONLY, {
    variables: {
      filters: JSON.stringify({
        fields: Filter.formatFields({
          accountId:
            (props.filters &&
              props.filters.accountId && {
                type: '=',
                value: props.filters.accountId,
              }) ||
            {},
          ...filters,
        }),
        date: {
          start: appliedStartDate.format('YYYY-MM-DD'),
          end: appliedEndDate.format('YYYY-MM-DD'),
        },
      }),
    },
  });
  console.log('DATA', data);

  const { formattedData, totals, formatLoading } = useFormatData(data);

  console.log('FORMATTED DATA: ', formattedData);

  /**
   * Render error notification if graphql fails
   */
  useEffect(() => {
    if (error) {
      NotificationStore.addNotification('error', error.message, 'Query Error');
    }
  }, [error]);

  /**
   * Column options used to render table columns
   */
  const columns = [
    {
      name: 'campaign',
      label: 'Campaign',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.campaign !== undefined ? columnDisplay.campaign : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            sticky
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Word'}
          />
        ),
        setCellProps: () => ({ style: stickyStyle }),
        customBodyRender: (value: any, tableMeta: any, updateRadio: any) => {
          return <span>{value !== 'Unknown' ? <Link to={`/campaigns/${tableMeta.rowData[1]}/adgroups${dateDrillDown}`}>{value}</Link> : value}</span>;
        },
      },
    },
    {
      name: 'campaignId',
      label: 'Campaign ID',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.campaignId !== undefined ? columnDisplay.campaignId : false,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Equals'}
          />
        ),
      },
    },
    {
      name: 'platform',
      label: 'Platform',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.platform !== undefined ? columnDisplay.platform : !props.filters || !props.filters.accountId,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Radio'}
            filterValues={[
              {
                value: 'AdWords',
                label: 'AdWords',
              },
              {
                value: 'Bing',
                label: 'Bing',
              },
            ]}
          />
        ),
      },
    },
    {
      name: 'account',
      label: 'Account',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.account !== undefined ? columnDisplay.account : !props.filters || !props.filters.accountId,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Word'}
          />
        ),
      },
    },
    {
      name: 'accountId',
      label: 'Account ID',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.accountId !== undefined ? columnDisplay.accountId : false,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Equals'}
          />
        ),
      },
    },
    {
      name: 'status',
      label: 'Status',
      rerender: filters,
      options: {
        filter: true,
        display: columnDisplay.status !== undefined ? columnDisplay.status : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Radio'}
            filterValues={[
              {
                value: 'Active',
                label: 'Active',
              },
              {
                value: 'Paused',
                label: 'Paused',
              },
              {
                value: 'Removed',
                label: 'Removed',
              },
            ]}
          />
        ),
      },
    },
    {
      name: 'impressions',
      label: 'Impressions',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.impressions !== undefined ? columnDisplay.impressions : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'none') : ''),
      },
    },
    {
      name: 'clicks',
      label: 'Clicks',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.clicks !== undefined ? columnDisplay.clicks : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'none') : ''),
      },
    },
    {
      name: 'ctr',
      label: 'CTR',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.ctr !== undefined ? columnDisplay.ctr : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'percent') : ''),
      },
    },
    {
      name: 'cost',
      label: 'Cost',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.cost !== undefined ? columnDisplay.cost : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'dollar') : ''),
      },
    },
    {
      name: 'mds',
      label: 'MDs',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.mds !== undefined ? columnDisplay.mds : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'none') : ''),
      },
    },
    {
      name: 'cpmd',
      label: 'CPMD',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.cpmd !== undefined ? columnDisplay.cpmd : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'dollar') : ''),
      },
    },
    {
      name: 'leads',
      label: 'Leads',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.leads !== undefined ? columnDisplay.leads : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'none') : ''),
      },
    },
    {
      name: 'cpl',
      label: 'CPL',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.cpl !== undefined ? columnDisplay.cpl : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'dollar') : ''),
      },
    },
    {
      name: 'mesoFormLeads',
      label: 'Meso Form Leads',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.mesoFormLeads !== undefined ? columnDisplay.mesoFormLeads : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'none') : ''),
      },
    },
    {
      name: 'cpmfl',
      label: 'CPMFL',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.cpmfl !== undefined ? columnDisplay.cpmfl : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'dollar') : ''),
      },
    },
    {
      name: 'accounts',
      label: 'Accounts',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.accounts !== undefined ? columnDisplay.accounts : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'none') : ''),
      },
    },
    {
      name: 'cpa',
      label: 'CPA',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.cpa !== undefined ? columnDisplay.cpa : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'dollar') : ''),
      },
    },
    {
      name: 'mesoAccounts',
      label: 'Meso Accounts',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.mesoAccounts !== undefined ? columnDisplay.mesoAccounts : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'none') : ''),
      },
    },
    {
      name: 'cpma',
      label: 'CPMA',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.cpma !== undefined ? columnDisplay.cpma : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'dollar') : ''),
      },
    },
    {
      name: 'qualifiedLeads',
      label: 'Qualified Leads',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.qualifiedLeads !== undefined ? columnDisplay.qualifiedLeads : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'none') : ''),
      },
    },
    {
      name: 'cpql',
      label: 'CPQL',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.cpql !== undefined ? columnDisplay.cpql : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'dollar') : ''),
      },
    },
    {
      name: 'viables',
      label: 'Viables',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.viables !== undefined ? columnDisplay.viables : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'none') : ''),
      },
    },
    {
      name: 'cpv',
      label: 'CPV',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.cpv !== undefined ? columnDisplay.cpv : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'dollar') : ''),
      },
    },
    {
      name: 'accountSendovers',
      label: 'Account Sendovers',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.accountSendovers !== undefined ? columnDisplay.accountSendovers : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'none') : ''),
      },
    },
    {
      name: 'cpas',
      label: 'CPAS',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.cpas !== undefined ? columnDisplay.cpas : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'dollar') : ''),
      },
    },
    {
      name: 'accountMeetings',
      label: 'Account Meetings',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.accountMeetings !== undefined ? columnDisplay.accountMeetings : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'none') : ''),
      },
    },
    {
      name: 'cpam',
      label: 'CPAM',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.cpam !== undefined ? columnDisplay.cpam : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'dollar') : ''),
      },
    },
    {
      name: 'customers',
      label: 'Customers',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.customers !== undefined ? columnDisplay.customers : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'none') : ''),
      },
    },
    {
      name: 'cpcust',
      label: 'CPCust',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.cpcust !== undefined ? columnDisplay.cpcust : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'dollar') : ''),
      },
    },
  ];

  // The tables final columns
  const finalColumns = Columns.formatColumns(columns, data);

  /**
   * Options for table
   */
  const options = {
    selectableRows: false,
    rowsPerPageOptions: [20, 50, 100],
    rowsPerPage: 20,
    responsive: 'scroll',
    filter: false,
    search: false,
    customToolbar: () => (
      <>
        <DatePicker
          startDate={filterStartDate}
          endDate={filterEndDate}
          onDatesChange={(startDate, endDate) => setFilterDate(startDate, endDate)}
          appliedEndDate={appliedEndDate}
          appliedStartDate={appliedStartDate}
          setAppliedDatesChange={setAppliedDate}
          applyDatesFilter={Filter.applyDatesFilter}
        />
        <CustomFilterList filters={filters} setFilter={setFilters} clearFilter={Filter.clearFilter} />
      </>
    ),
    customFooter: (
      count: number,
      page: number,
      rowsPerPage: number,
      changeRowsPerPage: (rowsPerPage: number) => void,
      changePage: (page: number) => void
    ) => (
        <CustomFooter
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          totals={totals}
          columns={Columns.formatColumns(columns, data)}
        />
      ),
    onColumnViewChange: (changedColumn: string, action: string) => {
      const newColumnDisplay: any = { ...columnDisplay };
      if (action === 'add') {
        newColumnDisplay[changedColumn] = true;
      } else if (action === 'remove') {
        newColumnDisplay[changedColumn] = false;
      }
      setColumnDisplay(newColumnDisplay);
    },
    onTableChange: (action: any, tableState: any) => {
      // Take a specific action based on change
      switch (action) {
        // Column has been sorted
        case 'sort':
          // Loop through columns and determine which one is sorted
          for (let i = 0; i < tableState.columns.length; i++) {
            if (tableState.columns[i].sortDirection) {
              const column = finalColumns[i].name;
              const direction = tableState.columns[i].sortDirection;
              setSort({ column, direction });
              break;
            }
          }
          break;
      }
    },
  };

  // Apply sorting to table based on state
  if (sort.column) {
    const index = finalColumns.findIndex((item) => item.name === sort.column);
    // @ts-ignore
    if (finalColumns[index] && finalColumns[index].options) finalColumns[index].options.sortDirection = sort.direction;
  }

  return (
    <DeprecatedDataTable
      loading={loading || formatLoading}
      title={'Campaigns'}
      data={formattedData && formattedData.campaigns}
      options={options}
      columns={finalColumns}
    />
  );
};

// HELPERS

/**
 * Style to make table sidebar sticky
 */
const stickyStyle = {
  position: 'sticky',
  left: 0,
  background: 'white',
  zIndex: 101,
  boxShadow: 'inset -1px 0px 0px rgba(224, 224, 224, 1)',
};
