import { useState, useEffect } from 'react';
import { Numbers } from '../utilities';

/**
 *
 * @param field - The field from the table
 * @param value - The value of the field
 */
const prettifyTotal = (field: string, value: string) => {
  if (value === 'Infinity' || value === 'NaN') return 'N/A';
  if (field.includes('cp') || field.includes('Cp')) return Numbers.prettifyNumber(value, 'dollar');
  else if (field.includes('cost')) return Numbers.prettifyNumber(value, 'dollar');
  else if (field.includes('ct')) return Numbers.prettifyNumber(value, 'percent');
  else return Numbers.prettifyNumber(value, 'none');
};

/**
 *
 * @param cost - The total cost passed from the table
 * @param value - The value that needs to get divided by
 */
const checkForInfinity = (cost: string, value: string) => {
  const average = parseFloat(cost) / parseFloat(value);
  if (average === Infinity) {
    return cost;
  } else return average;
};

/**
 * A helper hook that takes the data returned from the graphql and processes that to return ints
 * and floats in the table
 * @param data The data object passed to the hook from the gql statement
 */
export const useFormatData = (data: any) => {
  const [formattedData, setFormattedData] = useState(data);
  const [formatLoading, setFormatLoading] = useState(false);
  const [totals, setTotals] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    setFormatLoading(true);
    if (data && Object.keys(data).length) {
      // Create a new instance of the data object
      const formattedData = { ...data };
      const tableData = formattedData[Object.keys(data)[0]];
      // Loop over the rows and fields and convert to int or float
      for (const row of tableData) {
        for (const field in row) {
          // Check if the string is a float and convert it to a float
          if (row[field] && !isNaN(row[field]) && row[field].toString().indexOf('.') !== -1) row[field] = parseFloat(row[field]);
          // Check if the string is an int and convert it to an int
          else if (row[field] && !isNaN(row[field])) row[field] = parseInt(row[field], 10);
        }
      }
      // Set the totals for the bottom of the table
      const newTotals: { [key: string]: string } = {};

      // Only calculate totals if we get data back
      if (tableData.length) {
        for (const field of Object.keys(tableData[0])) {
          if (field.slice(0, 3) === 'max') {
            let max = 0;
            for (const row of tableData) {
              // If the field has something that is not a number break or the field contains id break
              if (isNaN(row[field])) {
                max = -1;
                break;
              } else if (row[field] > max) max = row[field];
            }
            newTotals[field] = max !== -1 ? max.toString() : 'N/A';
          } else {
            let sum = 0;
            for (const row of tableData) {
              // If the field has something that is not a number break or the field contains id break
              if (isNaN(row[field])) {
                sum = -1;
                break;
              } else sum += row[field];
            }
            newTotals[field] = sum !== -1 ? sum.toString() : 'N/A';
          }
        }

        const finalTotals: { [key: string]: string } = {};

        // Check for the specific fields to calc the total values
        for (const field of Object.keys(tableData[0])) {
          if (field === 'ctr') finalTotals[field] = prettifyTotal(field, checkForInfinity(newTotals.clicks, newTotals.impressions).toString());
          else if (field === 'cpmd') finalTotals[field] = prettifyTotal(field, checkForInfinity(newTotals.cost, newTotals.mds).toString());
          else if (field === 'cpl') finalTotals[field] = prettifyTotal(field, checkForInfinity(newTotals.cost, newTotals.leads).toString());
          else if (field === 'cpmfl') finalTotals[field] = prettifyTotal(field, checkForInfinity(newTotals.cost, newTotals.mesoFormLeads).toString());
          else if (field === 'cpa') finalTotals[field] = prettifyTotal(field, checkForInfinity(newTotals.cost, newTotals.accounts).toString());
          else if (field === 'cpma') finalTotals[field] = prettifyTotal(field, checkForInfinity(newTotals.cost, newTotals.mesoAccounts).toString());
          else if (field === 'cpql') finalTotals[field] = prettifyTotal(field, checkForInfinity(newTotals.cost, newTotals.qualifiedLeads).toString());
          else if (field === 'cpv') finalTotals[field] = prettifyTotal(field, checkForInfinity(newTotals.cost, newTotals.viables).toString());
          else if (field === 'cpas')
            finalTotals[field] = prettifyTotal(field, checkForInfinity(newTotals.cost, newTotals.accountSendovers).toString());
          else if (field === 'cpam')
            finalTotals[field] = prettifyTotal(field, checkForInfinity(newTotals.cost, newTotals.accountMeetings).toString());
          else if (field === 'cpcust') finalTotals[field] = prettifyTotal(field, checkForInfinity(newTotals.cost, newTotals.customers).toString());
          else if (field === 'cpc') finalTotals[field] = prettifyTotal(field, checkForInfinity(newTotals.cost, newTotals.clicks).toString());
          else if (field.includes('Id')) finalTotals[field] = 'N/A';
          else finalTotals[field] = prettifyTotal(field, newTotals[field]);
        }
        setTotals(finalTotals);
      }

      setFormattedData(formattedData);
      setFormatLoading(false);
    }
  }, [data]);

  return {
    totals,
    formattedData,
    formatLoading,
  };
};
