import React, { Fragment, useState, useEffect } from 'react';
import { DataModal, Input, Button, CheckboxList, Label } from 'lt-components';
import { useModalRoute } from '../../../../hooks';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { NotificationStore, UserStore } from '../../../../stores';
import gql from 'graphql-tag';
import { useQuery, useMutation } from 'react-apollo-hooks';
import './UserModal.scss';

/**
 * Get accounts graphql query
 */
const GET_ACCOUNTS = gql`
  query getUsers {
    accounts(filters: $filters) @rest(type: "Accounts", path: "/get-unique-accounts/") {
      accountId
      account
      platform
    }
  }
`;

/**
 * Update user account permissions graphql query
 */
const UPDATE_ACCOUNTS = gql`
  mutation updateAccounts {
    updateAccountsResponse(input: $input) @rest(type: "Post", path: "/update-permissions/", method: "POST") {
      NoResponse
    }
  }
`;

/**
 * Defines expected types in a user
 */
interface IUser {
  /**
   * the user's id
   */
  id: number;
  /**
   * the user's name
   */
  name?: string;
  /**
   * the user's email
   */
  email?: string;
  /**
   * the user's list of account permissions
   */
  accounts: number[];
}

/**
 * Defines expected props for this component
 */
interface IProps {
  /**
   * className applied to component
   */
  className?: string;
  /**
   * whether the parent is still loading the users
   */
  loading: boolean;
  /**
   * function to run when modal is being closed to navigate back to the page
   */
  navigateBack: (...args: any[]) => void;
  /**
   * list of users retrieved by parent page (so we don't need another query)
   */
  users?: IUser[];
}

/**
 * The modal for user data
 */
const UserModalBase = (props: IProps & RouteComponentProps<any>) => {
  // Extract route params to figure out which item is being modified or created
  const id = props.match.params.id;
  // Track which user is being edited
  const [user, setUser] = useState<IUser>();
  const [userError, setUserError] = useState(false);

  // Query database with graphql (need accounts to manage permissions)
  const { data, error, loading } = useQuery(GET_ACCOUNTS);
  const [accountMutation, { loading: mutationLoading, error: mutationError }] = useMutation(UPDATE_ACCOUNTS);

  // State to manage modal open state
  const [open, closeModal] = useModalRoute(props.navigateBack);

  // Track submission of query
  const [submitted, setSubmitted] = useState(false);

  // Track current account permissions
  const [accountPermissions, setAccountPermissions] = useState<any>([]);

  // Set header based on operation being performed
  const headerText = UserStore.user && UserStore.user.isAdmin ? 'Edit User' : 'View User ';

  /**
   * Generate the user info by looking in the users object we received in the Users page
   */
  useEffect(() => {
    if (props.users) {
      const userIndex = props.users.findIndex((current) => current.id === parseInt(id, 10));

      // If the user exists, set them
      if (userIndex !== -1) {
        setUser(props.users[userIndex]);
      }
      // Otherwise throw an error
      else setUserError(true);
    }
  }, [props.users]);

  /**
   * Generate account options once it finishes loading
   */
  useEffect(() => {
    if (!loading && user && data.accounts) {
      const options = [];

      // Option is checked if it exists in the user's permissions
      for (const option of data.accounts) {
        options.push({
          label: `${option.account} - ${option.platform}`,
          value: option.accountId,
          checked: user.accounts.includes(option.accountId),
        });
      }

      setAccountPermissions(options);
    }
  }, [user, loading]);

  /**
   * Use mutator to update the user's accounts
   */
  const updateAccounts = () => {
    if (user) {
      const accounts: number[] = [];

      // Format account permissions
      for (const permission of accountPermissions) {
        if (permission.checked) accounts.push(permission.value);
      }

      // Run mutation to update account
      accountMutation({
        variables: {
          input: {
            id: user.id,
            accounts,
          },
        },
      });

      setSubmitted(true);
    }
  };

  /**
   * Navigate back after query succeeds
   */
  useEffect(() => {
    if (!mutationLoading && submitted && !mutationError) {
      // If there were no errors, show a success message and navigate back
      NotificationStore.addNotification('success', 'Permissions changed successfully', `Success`, 2000);
      props.navigateBack(true);
    }
  }, [mutationLoading, submitted, mutationError]);

  /**
   * Render error notification if graphql fetch fails
   */
  useEffect(() => {
    if (error) {
      NotificationStore.addNotification('error', error.message, 'Query Error');
    }
  }, [error]);

  /**
   * Render error notification if the user is not found
   */
  useEffect(() => {
    if (userError) {
      NotificationStore.addNotification('error', "The user could not be found. Verify you're using a valid id.", 'User Error');
    }
  }, [userError]);

  /**
   * Render error notification if graphql update fails
   */
  useEffect(() => {
    if (mutationError) {
      NotificationStore.addNotification('error', mutationError.message, 'Update Error');
    }
  }, [mutationError]);

  /**
   * Handle changes in account permissions
   * @param account - the value of the account permission being toggled
   */
  const changeAccountPermissions = (account: string) => {
    const newPermissions = accountPermissions.slice();
    for (const permission of newPermissions) {
      if (permission.value === account) permission.checked = !permission.checked;
    }
    setAccountPermissions(newPermissions);
  };

  /**
   * Detail tab for modal
   */
  const detailsTab = () => {
    return (
      <Fragment>
        <div className='row'>
          <div className='column'>
            <Input label='Name' value={user ? user.name : ''} autoComplete='off' disabled={true} />
          </div>
        </div>
        <div className='row'>
          <div className='column userModal__permissionsContainer'>
            <Input label='Email' value={user ? user.email : ''} autoComplete='off' disabled={true} />
          </div>
        </div>

        <div className='row'>
          <div className='column'>
            <Label text={'Account Permissions'} />
            <div className='userModal__checkboxContainer'>
              <CheckboxList
                values={accountPermissions}
                disabled={UserStore.user && !UserStore.user.isAdmin}
                onChange={(event) => changeAccountPermissions(event.target.value)}
              />
            </div>
          </div>
        </div>

        {// Remove button if user cannot edit anyways
        UserStore.user && UserStore.user.isAdmin && (
          <div className='row m-0'>
            <Button className='dataModal__button' onClick={() => updateAccounts()}>
              Save
            </Button>
          </div>
        )}
      </Fragment>
    );
  };

  return (
    <DataModal
      headerText={headerText}
      loading={props.loading || loading || !user || mutationLoading}
      open={open}
      onClose={closeModal}
      activeTab={0}
      className={`${props.className ? props.className : ''}`}
      tabs={[
        {
          title: 'Details',
          body: props.loading || loading || !user ? undefined : detailsTab(),
        },
      ]}
    />
  );
};

/**
 * The modal for user data
 */
export const UserModal = withRouter(UserModalBase);
