import { useState, useEffect } from 'react';
import moment from 'moment';
import { useDates } from './';
import { Filter } from '../utilities';

/**
 * The logic for managing the table state values and filters all back into a nice conveinient hook
 * @param defaultSort - The default value for the sort function(This changes per table)
 */
export const useFilters = (defaultSort: { column: string; direction: 'asc' | 'desc' }) => {
  // If there are filters in the url, retrieve the filters
  const urlFilter = window.location.hash ? JSON.parse(decodeURI(window.location.hash).replace('#', '')) : {};
  // Get the filters to start with
  const startFilters = urlFilter.filters ? urlFilter.filters : {};
  // Get the date to start with
  const startDate = urlFilter.date ? moment(urlFilter.date.startDate) : Filter.defaultStartDate;
  // Get the end date to start with
  const endDate = urlFilter.date ? moment(urlFilter.date.endDate) : Filter.defaultEndDate;
  // Get the sort to start with
  const startSort = urlFilter.sort ? urlFilter.sort : defaultSort;
  // Date filter
  const [filterStartDate, filterEndDate, setFilterDate] = useDates(startDate, endDate);
  // The dates that are applied to the query
  const [appliedStartDate, appliedEndDate, setAppliedDate] = useDates(startDate, endDate);
  // Actual filter state
  const [filters, setFilters] = useState(startFilters);
  // Determine if the column should be displayed
  const [columnDisplay, setColumnDisplay] = useState<any>({});
  // Track sort direction
  const [sort, setSort] = useState(startSort);
  // Create drill down link that keeps date filter
  const [dateDrillDown, setDateDrillDown] = useState(urlFilter.date ? `#${JSON.stringify({ date: urlFilter.date })}` : '');
  // Skip the first use effect run
  const [skipFirst, setSkipFirst] = useState(true);
  // Logic for adding in the JSON parse to the url when filters, startDate, endDate or sort are changed
  useEffect(() => {
    // Don't run on first iteration
    if (!skipFirst) {
      const urlFilter: any = {};
      // If a non default sort is applied, add it to the filter URL
      if (sort.column !== defaultSort.column || sort.direction !== defaultSort.direction) {
        urlFilter.sort = sort;
      }
      // If the filter date is no longer default, add it to the filter URL
      if (
        appliedEndDate.format('YYYY-MM-DD') !== Filter.defaultEndDate.format('YYYY-MM-DD') ||
        appliedStartDate.format('YYYY-MM-DD') !== Filter.defaultStartDate.format('YYYY-MM-DD')
      ) {
        urlFilter.date = {
          startDate: appliedStartDate.format('YYYY-MM-DD'),
          endDate: appliedEndDate.format('YYYY-MM-DD'),
        };
        setDateDrillDown(`#${JSON.stringify({ date: urlFilter.date })}`);
      }
      // Clear date drill down if there is no longer a special date range
      else {
        setDateDrillDown('');
      }

      // Apply any additional filters to the filter URL
      if (Object.values(filters).length) {
        urlFilter.filters = filters;
      }
      // Modify URL to include filters
      if (!Object.values(urlFilter).length) history.pushState('', document.title, window.location.pathname);
      else window.location.hash = JSON.stringify(urlFilter);
      // Make sure the logic runs after this first iteration
    } else {
      setSkipFirst(false);
    }
  }, [filters, appliedStartDate, appliedEndDate, sort]);

  // Check if the user is inside of the page
  document.onmouseover = () => {
    // User's mouse is inside the page.
    // @ts-ignore
    window.innerDocClick = true;
  };
  // Used to detect if the user left the page to get the back button clicks
  document.onmouseleave = () => {
    // User's mouse has left the page.
    // @ts-ignore
    window.innerDocClick = false;
  };

  // Check for the hash changes
  window.onhashchange = () => {
    // If the user is outside of the doc then run the function    
    // @ts-ignore
    if (!window.innerDocClick) {
      // If there are filters in the url, retrieve the filters
      const urlFilter = window.location.hash ? JSON.parse(decodeURI(window.location.hash).replace('#', '')) : {};
      // Get the filters to start with
      const filters = urlFilter.filters ? urlFilter.filters : {};
      // Get the date to start with
      const startDate = urlFilter.date ? moment(urlFilter.date.startDate) : Filter.defaultStartDate;
      // Get the end date to start with
      const endDate = urlFilter.date ? moment(urlFilter.date.endDate) : Filter.defaultEndDate;
      // Get the sort to start with
      const sort = urlFilter.sort ? urlFilter.sort : defaultSort;

      setFilters(filters);
      setAppliedDate(startDate, endDate);
      setSort(sort);
    }
  };

  return {
    filters,
    sort,
    filterStartDate,
    filterEndDate,
    appliedStartDate,
    appliedEndDate,
    columnDisplay,
    setColumnDisplay,
    setFilterDate,
    setAppliedDate,
    setSort,
    setFilters,
    dateDrillDown,
  };
};
