import React, { useEffect } from 'react';
import { DeprecatedDataTable, CustomHeadRender, DatePicker, CustomFilterList, CustomFooter } from 'lt-components';
import { useFilters, useFormatData } from '../../hooks';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo-hooks';
import { Filter, Columns, Numbers } from '../../utilities';
import './CreativesTable.scss';
import { NotificationStore, UserStore } from '../../stores';

/**
 * Get creatives graphql query
 */
const GET_CREATIVES = gql`
  query getCreatives {
    creatives(filters: $filters) @rest(type: "Creative", path: "/get-creatives/?{args}") {
      account
      accountId
      campaign
      campaignId
      adgroup
      adgroupId
      creativeId
      platform
      url
      visual
      headline1
      headline2
      headline3
      description
      status
      impressions
      clicks
      cost
      ctr
      mds
      cpmd
      leads
      cpl
      mesoFormLeads
      cpmfl
      accounts
      cpa
      mesoAccounts
      cpma
      qualifiedLeads
      cpql
      viables
      cpv
      accountSendovers
      cpas
      accountMeetings
      cpam
      customers
      cpcust
    }
  }
`;

/**
 * Get Creatives graphql query for only people with agency permissions
 */
const GET_CREATIVES_AGENCY_ONLY = gql`
  query getCreatives {
    creatives(filters: $filters) @rest(type: "Creative", path: "/get-creatives/?{args}") {
      account
      accountId
      campaign
      campaignId
      adgroup
      adgroupId
      creativeId
      platform
      url
      visual
      headline1
      headline2
      headline3
      description
      status
      impressions
      clicks
      cost
      ctr
      cpc
      maxCpc
      mds
      cpmd
      leads
      cpl
      qualifiedLeads
      cpql
      customers
      cpcust
    }
  }
`;

/**
 * Defines expected props for this component
 */
interface IProps {
  /**
   * the initial filter options determined by the parent drill-down
   */
  filters?: {
    /**
     * the id of the account that was drilled-down
     */
    accountId?: number;
    /**
     * the id of the campaign that was drilled-down
     */
    campaignId?: number;
    /**
     * the id of the adgroup that was drilled-down
     */
    adgroupId?: number;
  };
}

/**
 * The creatives table component (handles it's own state / filters)
 */
export const CreativesTable = (props: IProps) => {
  // Manages the state of the filters and the table
  const {
    filters,
    sort,
    filterStartDate,
    filterEndDate,
    appliedStartDate,
    appliedEndDate,
    columnDisplay,
    setColumnDisplay,
    setFilterDate,
    setAppliedDate,
    setSort,
    setFilters,
  } = useFilters({ column: 'headline1', direction: 'asc' });

  // Query database with graphql (applying filters)
  const { data, error, loading } = useQuery(UserStore.user && UserStore.user.mesoPermissions ? GET_CREATIVES : GET_CREATIVES_AGENCY_ONLY, {
    variables: {
      filters: JSON.stringify({
        fields: Filter.formatFields({
          accountId:
            (props.filters &&
              props.filters.accountId && {
                type: '=',
                value: props.filters.accountId,
              }) ||
            {},
          campaignId:
            (props.filters &&
              props.filters.campaignId && {
                type: '=',
                value: props.filters.campaignId,
              }) ||
            {},
          adgroupId:
            (props.filters &&
              props.filters.adgroupId && {
                type: '=',
                value: props.filters.adgroupId,
              }) ||
            {},
          ...filters,
        }),
        date: {
          start: appliedStartDate.format('YYYY-MM-DD'),
          end: appliedEndDate.format('YYYY-MM-DD'),
        },
      }),
    },
  });
  console.log('DATA', data);

  const { formattedData, totals, formatLoading } = useFormatData(data);

  console.log('FORMATTED DATA: ', formattedData);

  /**
   * Render error notification if graphql fails
   */
  useEffect(() => {
    if (error) {
      NotificationStore.addNotification('error', error.message, 'Query Error');
    }
  }, [error]);

  /**
   * Remove brackets around URLs
   * @param url - the url text to prettify
   */
  const prettifyUrls = (url: string) => {
    if (url !== null && url.charAt(0) === '[') {
      return url.slice(2, url.length - 2);
    } else return url;
  };

  /**
   * Render the creative in it's column
   * @param headline - the headline of the creative
   * @param url - the url of the creative
   * @param description - the description of the creative
   * @param campaign - the campaign of the creative
   */
  const renderCreative = (headline: string, url: string, description: string, campaign: string) => {
    // If no headline was provided, render something special
    if (!headline) {
      // If the campaign display, just say display
      if (campaign === 'Custom Intent Display') {
        return (
          <div className={'creativesTable__creativeWrapper'}>
            <span>Display Ad</span>
          </div>
        );
      }
      // Otherwise, it's an RSA ad
      else {
        return (
          <div className={'creativesTable__creativeWrapper'}>
            <span>RSA</span>
          </div>
        );
      }
    } else {
      return (
        <div className={'creativesTable__creativeWrapper'}>
          <span className={'creativesTable__headline'}>{headline}</span>
          <div className={'creativesTable__description'}>
            <span>{description}</span>
          </div>
          <div className={'creativesTable__displayUrl'}>{url}</div>
        </div>
      );
    }
  };

  /**
   * Column options used to render table columns
   */
  const columns = [
    {
      name: 'visual',
      label: 'Creative',
      rerender: filters,
      options: {
        sort: false,
        display: columnDisplay.visual !== undefined ? columnDisplay.visual : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender sticky key={columnMeta.index} columnMeta={columnMeta} handleToggleColumn={handleToggleColumn} fixedHeader />
        ),
        setCellProps: () => ({ style: stickyStyle }),
        customBodyRender: (value: any, tableMeta: any, updateRadio: any) => {
          const headline =
            tableMeta.rowData && tableMeta.rowData[1]
              ? `${tableMeta.rowData[1]}${(tableMeta.rowData[2] && ` | ${tableMeta.rowData[2]}`) || ''}${(tableMeta.rowData[3] &&
                ` | ${tableMeta.rowData[3]}`) ||
              ''}`
              : '';
          const url = tableMeta.rowData ? prettifyUrls(tableMeta.rowData[5]) : '';
          const description = tableMeta.rowData ? tableMeta.rowData[4] : '';
          const campaign = tableMeta.rowData ? tableMeta.rowData[10] : '';
          return renderCreative(headline, url, description, campaign);
        },
      },
    },
    {
      name: 'headline1',
      label: 'Headline1',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.headline1 !== undefined ? columnDisplay.headline1 : false,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Word'}
          />
        ),
      },
    },
    {
      name: 'headline2',
      label: 'Headline2',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.headline2 !== undefined ? columnDisplay.headline2 : false,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Word'}
          />
        ),
      },
    },
    {
      name: 'headline3',
      label: 'Headline3',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.headline3 !== undefined ? columnDisplay.headline3 : false,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Word'}
          />
        ),
      },
    },
    {
      name: 'description',
      label: 'Description',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.description !== undefined ? columnDisplay.description : false,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Word'}
          />
        ),
        customBodyRender: (value: any, tableMeta: any, updateRadio: any) => {
          return (
            <div className={'creativesTable__description_cell'}>
              <span>{value}</span>
            </div>
          );
        },
      },
    },
    {
      name: 'url',
      label: 'Url',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.url !== undefined ? columnDisplay.url : false,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Word'}
          />
        ),
        customBodyRender: (value: any, tableMeta: any, updateRadio: any) => (value ? prettifyUrls(value) : ''),
      },
    },
    {
      name: 'creativeId',
      label: 'Creative ID',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.creativeId !== undefined ? columnDisplay.creativeId : false,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            applyFilter={Filter.applyFilter}
            columnMeta={columnMeta}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Equals'}
          />
        ),
      },
    },
    {
      name: 'platform',
      label: 'Platform',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display:
          columnDisplay.platform !== undefined
            ? columnDisplay.platform
            : !props.filters || (!props.filters.accountId && !props.filters.campaignId && !props.filters.adgroupId),
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Radio'}
            filterValues={[
              {
                value: 'AdWords',
                label: 'AdWords',
              },
              {
                value: 'Bing',
                label: 'Bing',
              },
            ]}
          />
        ),
      },
    },
    {
      name: 'account',
      label: 'Account',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display:
          columnDisplay.account !== undefined
            ? columnDisplay.account
            : !props.filters || (!props.filters.accountId && !props.filters.campaignId && !props.filters.adgroupId),
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Word'}
          />
        ),
      },
    },
    {
      name: 'accountId',
      label: 'Account ID',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.accountId !== undefined ? columnDisplay.accountId : false,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Equals'}
          />
        ),
      },
    },
    {
      name: 'campaign',
      label: 'Campaign',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display:
          columnDisplay.campaign !== undefined ? columnDisplay.campaign : !props.filters || (!props.filters.campaignId && !props.filters.adgroupId),
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Word'}
          />
        ),
      },
    },
    {
      name: 'campaignId',
      label: 'Campaign ID',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.campaignId !== undefined ? columnDisplay.campaignId : false,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            applyFilter={Filter.applyFilter}
            columnMeta={columnMeta}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Equals'}
          />
        ),
      },
    },
    {
      name: 'adgroup',
      label: 'Adgroup',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.adgroup !== undefined ? columnDisplay.adgroup : !props.filters || !props.filters.adgroupId,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            applyFilter={Filter.applyFilter}
            columnMeta={columnMeta}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Word'}
          />
        ),
      },
    },
    {
      name: 'adgroupId',
      label: 'Adgroup ID',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.adgroupId !== undefined ? columnDisplay.adgroupId : false,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Equals'}
          />
        ),
      },
    },
    {
      name: 'status',
      label: 'Status',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.status !== undefined ? columnDisplay.status : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Radio'}
            filterValues={[
              {
                value: 'Active',
                label: 'Active',
              },
              {
                value: 'Paused',
                label: 'Paused',
              },
              {
                value: 'Removed',
                label: 'Removed',
              },
            ]}
          />
        ),
      },
    },
    {
      name: 'impressions',
      label: 'Impressions',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.impressions !== undefined ? columnDisplay.impressions : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'none') : ''),
      },
    },
    {
      name: 'clicks',
      label: 'Clicks',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.clicks !== undefined ? columnDisplay.clicks : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'none') : ''),
      },
    },
    {
      name: 'ctr',
      label: 'CTR',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.ctr !== undefined ? columnDisplay.ctr : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'percent') : ''),
      },
    },
    {
      name: 'cost',
      label: 'Cost',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.cost !== undefined ? columnDisplay.cost : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'dollar') : ''),
      },
    },
    {
      name: 'mds',
      label: 'MDs',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.mds !== undefined ? columnDisplay.mds : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'none') : ''),
      },
    },
    {
      name: 'cpmd',
      label: 'CPMD',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.cpmd !== undefined ? columnDisplay.cpmd : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'dollar') : ''),
      },
    },
    {
      name: 'leads',
      label: 'Leads',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.leads !== undefined ? columnDisplay.leads : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'none') : ''),
      },
    },
    {
      name: 'cpl',
      label: 'CPL',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.cpl !== undefined ? columnDisplay.cpl : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'dollar') : ''),
      },
    },
    {
      name: 'mesoFormLeads',
      label: 'Meso Form Leads',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.mesoFormLeads !== undefined ? columnDisplay.mesoFormLeads : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'none') : ''),
      },
    },
    {
      name: 'cpmfl',
      label: 'CPMFL',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.cpmfl !== undefined ? columnDisplay.cpmfl : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'dollar') : ''),
      },
    },
    {
      name: 'accounts',
      label: 'Accounts',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.accounts !== undefined ? columnDisplay.accounts : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'none') : ''),
      },
    },
    {
      name: 'cpa',
      label: 'CPA',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.cpa !== undefined ? columnDisplay.cpa : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'dollar') : ''),
      },
    },
    {
      name: 'mesoAccounts',
      label: 'Meso Accounts',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.mesoAccounts !== undefined ? columnDisplay.mesoAccounts : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'none') : ''),
      },
    },
    {
      name: 'cpma',
      label: 'CPMA',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.cpma !== undefined ? columnDisplay.cpma : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'dollar') : ''),
      },
    },
    {
      name: 'qualifiedLeads',
      label: 'Qualified Leads',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.qualifiedLeads !== undefined ? columnDisplay.qualifiedLeads : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'none') : ''),
      },
    },
    {
      name: 'cpql',
      label: 'CPQL',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.cpql !== undefined ? columnDisplay.cpql : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'dollar') : ''),
      },
    },
    {
      name: 'viables',
      label: 'Viables',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.viables !== undefined ? columnDisplay.viables : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'none') : ''),
      },
    },
    {
      name: 'cpv',
      label: 'CPV',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.cpv !== undefined ? columnDisplay.cpv : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'dollar') : ''),
      },
    },
    {
      name: 'accountSendovers',
      label: 'Account Sendovers',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.accountSendovers !== undefined ? columnDisplay.accountSendovers : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'none') : ''),
      },
    },
    {
      name: 'cpas',
      label: 'CPAS',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.cpas !== undefined ? columnDisplay.cpas : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'dollar') : ''),
      },
    },
    {
      name: 'accountMeetings',
      label: 'Account Meetings',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.accountMeetings !== undefined ? columnDisplay.accountMeetings : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'none') : ''),
      },
    },
    {
      name: 'cpam',
      label: 'CPAM',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.cpam !== undefined ? columnDisplay.cpam : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'dollar') : ''),
      },
    },
    {
      name: 'customers',
      label: 'Customers',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.customers !== undefined ? columnDisplay.customers : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'none') : ''),
      },
    },
    {
      name: 'cpcust',
      label: 'CPCust',
      rerender: filters,
      options: {
        filter: true,
        sort: true,
        display: columnDisplay.cpcust !== undefined ? columnDisplay.cpcust : true,
        customHeadRender: (columnMeta: any, handleToggleColumn: any) => (
          <CustomHeadRender
            key={columnMeta.index}
            columnMeta={columnMeta}
            applyFilter={Filter.applyFilter}
            filters={filters}
            setFilter={setFilters}
            clearFilter={Filter.clearFilter}
            handleToggleColumn={handleToggleColumn}
            fixedHeader
            filter={'Number'}
          />
        ),
        customBodyRender: (value: string, tableMeta: any) => (value !== null ? Numbers.prettifyNumber(value, 'dollar') : ''),
      },
    },
  ];

  // The tables final columns
  const finalColumns = Columns.formatColumns(columns, data);

  /**
   * Options for table
   */
  const options = {
    selectableRows: false,
    rowsPerPageOptions: [20, 50, 100],
    rowsPerPage: 20,
    responsive: 'scroll',
    filter: false,
    search: false,
    customToolbar: () => (
      <>
        <DatePicker
          startDate={filterStartDate}
          endDate={filterEndDate}
          onDatesChange={(startDate, endDate) => setFilterDate(startDate, endDate)}
          appliedEndDate={appliedEndDate}
          appliedStartDate={appliedStartDate}
          setAppliedDatesChange={setAppliedDate}
          applyDatesFilter={Filter.applyDatesFilter}
        />
        <CustomFilterList filters={filters} setFilter={setFilters} clearFilter={Filter.clearFilter} />
      </>
    ),
    customFooter: (
      count: number,
      page: number,
      rowsPerPage: number,
      changeRowsPerPage: (rowsPerPage: number) => void,
      changePage: (page: number) => void
    ) => (
        <CustomFooter
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          totals={totals}
          columns={Columns.formatColumns(columns, data)}
        />
      ),
    onColumnViewChange: (changedColumn: string, action: string) => {
      const newColumnDisplay: any = { ...columnDisplay };
      if (action === 'add') {
        newColumnDisplay[changedColumn] = true;
      } else if (action === 'remove') {
        newColumnDisplay[changedColumn] = false;
      }
      setColumnDisplay(newColumnDisplay);
    },
    onTableChange: (action: any, tableState: any) => {
      // Take a specific action based on change
      switch (action) {
        // Column has been sorted
        case 'sort':
          // Loop through columns and determine which one is sorted
          for (let i = 0; i < tableState.columns.length; i++) {
            if (tableState.columns[i].sortDirection) {
              const column = finalColumns[i].name;
              const direction = tableState.columns[i].sortDirection;
              setSort({ column, direction });
              break;
            }
          }
          break;
      }
    },
  };

  // Apply sorting to table based on state
  if (sort.column) {
    const index = finalColumns.findIndex((item) => item.name === sort.column);
    // @ts-ignore
    if (finalColumns[index] && finalColumns[index].options) finalColumns[index].options.sortDirection = sort.direction;
  }

  return (
    <DeprecatedDataTable
      loading={loading || formatLoading}
      title={'Creatives'}
      data={formattedData && formattedData.creatives}
      options={options}
      columns={finalColumns}
    />
  );
};

// HELPERS

/**
 * Style to make table sidebar sticky
 */
const stickyStyle = {
  position: 'sticky',
  left: 0,
  background: 'white',
  zIndex: 101,
  boxShadow: 'inset -1px 0px 0px rgba(224, 224, 224, 1)',
};
