import React, { useState, useEffect } from 'react';
import { Button, DeprecatedDataTable, Loading, OperationButtons, DeleteModal } from 'lt-components';
import { UserStore, NotificationStore } from '../../stores';
import { withRouter, Route, RouteComponentProps } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo-hooks';
import { UserModal } from './components';
import './Users.scss';

/**
 * Get users graphql query
 */
const GET_USERS = gql`
  query getUsers {
    users(refresh: $refresh) @rest(type: "User", path: "/get-users/") {
      id
      name
      email
      accounts
      ops
    }
  }
`;

/**
 * Defines expected props for this component
 */
interface IProps {
  /**
   * className applied to component
   */
  className?: string;
}

/**
 * The users page
 */
const UsersBase = (props: IProps & RouteComponentProps) => {
  const [refresh, setRefresh] = useState(0);

  // Query database with graphql
  const { data, error, loading } = useQuery(GET_USERS, {
    variables: {
      refresh,
    },
  });
  console.log(data);

  /**
   * Render error notification if graphql fails
   */
  useEffect(() => {
    if (error) {
      NotificationStore.addNotification('error', error.message, 'Query Error');
    }
  }, [error]);

  /**
   * Navigate back to users page and refresh data
   * @param refresh - whether to refresh the table (should be true when data was edited)
   */
  const navigateBack = (forceRefresh?: boolean) => {
    // Refresh if asked
    if (forceRefresh) setRefresh(refresh + 1);
    // Navigate back to table
    props.history.push('/users');
  };

  /**
   * Edit the selected item (open modal)
   */
  const editItem = (id: string) => {
    props.history.push(`/users/${id}`);
  };

  /**
   * Options for the table
   */
  const options = {
    selectableRows: false,
    rowsPerPageOptions: [20, 50, 100],
    rowsPerPage: 20,
    responsive: 'scroll',
    filter: false,
    search: true,
  };

  /**
   * Columns for the table
   */
  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'ops',
      label: 'Operations',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          // If the user is an admin, return the edit button
          if (UserStore.user && UserStore.user.isAdmin) {
            return <OperationButtons edit={() => editItem(tableMeta.rowData ? tableMeta.rowData[0] : -1)} />;
          }
          // Otherwise return the view button
          else {
            return <OperationButtons view={() => editItem(tableMeta.rowData ? tableMeta.rowData[0] : -1)} />;
          }
        },
      },
    },
  ];

  return (
    <div className='full-container'>
      <DeprecatedDataTable loading={loading} title={'Users'} options={options} columns={columns} data={data && data.users} />

      <Route
        path='/users/:id'
        render={() => {
          return <UserModal navigateBack={navigateBack} users={data && data.users} loading={loading} />;
        }}
      />
    </div>
  );
};

/**
 * The users page
 */
export const Users = withRouter(UsersBase);
